import React from "react"
import { getPhoneIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"

interface AnchorNumberProps {
  phoneNumber?: string
  trackingNumber?: string
  label?: string
  svgIcon?: string
  classNameModifier?: string
  showIcon?: boolean
  customTrackingNumber?: string
  isLandingPage?: boolean
}

export const AnchorNumber = ({
  phoneNumber,
  trackingNumber,
  label,
  svgIcon,
  classNameModifier = "",
  showIcon = true,
  customTrackingNumber,
  isLandingPage,
}: AnchorNumberProps) => {
  const { usePhoneNumberNotTrackingNumber } = useGeneralSettings()
  const number = customTrackingNumber && customTrackingNumber.length > 0 && isLandingPage
  ? customTrackingNumber
  : usePhoneNumberNotTrackingNumber
    ? phoneNumber
    : trackingNumber;
  return (
    <a
      href={`tel: ${number}`}
      className={`fr-header__phone-${classNameModifier}`}
    >
      {label && <div className="fr-header__extra-call-text">{label}</div>}
      {showIcon && svgIcon && (
        <span
          dangerouslySetInnerHTML={{ __html: svgIcon }}
          contentEditable="false"
          className="phone-svg-container"
        />
      )}
      {showIcon && !svgIcon && <>{getPhoneIcon()}</>}
      <h5 className={`fr-header__phone-${classNameModifier}__text`}>
        {number}
        </h5>
    </a>
  )
}
