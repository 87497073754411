import React from "react"
import { Link } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { Credits } from "./Credits"
import { SocialIcons } from "../social-icons/SocialIcons"
import { FooterLocation } from "./FooterLocation"
import "./footer.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { ImageBuilder } from "_types/AssetsImage"

interface FooterProps {
  logo: ImageBuilder
  creditsNavigation: NavigationLinksOutput[]
  footerNavigation: NavigationLinksOutput[]
  isLandingPage?: boolean
  customTrackingNumber?: string
}

export const Footer = ({
  logo,
  creditsNavigation,
  isLandingPage,
  customTrackingNumber,
}: FooterProps) => {
  const {
    customCTAText,
    socialMedia,
    trackingNumbers,
    formattedOffices,
    usePhoneNumberNotTrackingNumber,
    companyName,
  } = useGeneralSettings()

  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber

  const isLessThanOrEqualTwoOffices = formattedOffices.length <= 2
  const isMoreThanTwoModifier = isLessThanOrEqualTwoOffices
    ? ""
    : "--more-than-2"

  return (
    <div className="page-content-above-parallax-bg-image">
      <footer className="fr-footer alternate-background-color u-content-block-box">
        <div
          className={`fr-container fr-footer__inner${isMoreThanTwoModifier}`}
        >
          <div className="fr-footer__logo-container">
            <Link
              to="/"
              className="base-logo"
              aria-label={`Go to ${companyName} Homepage`}
            >
              <img
                src={`${logo.url}`}
                alt={logo.description || `${companyName} Logo`}
                loading="lazy"
              />
            </Link>
          </div>

          <p className="fr-footer__cta">{customCTAText}</p>

          {contactNumber && (
            <div className="fr-footer__phone-container">
              <a
                href={`tel: ${
                  customTrackingNumber &&
                  customTrackingNumber?.length > 0 &&
                  isLandingPage
                    ? customTrackingNumber
                    : contactNumber
                }`}
                className="fr-footer__phone"
              >
                {customTrackingNumber &&
                customTrackingNumber?.length > 0 &&
                isLandingPage
                  ? customTrackingNumber
                  : contactNumber}
              </a>
            </div>
          )}

          {socialMedia.length > 0 && (
            <div className="fr-footer__social-container">
              {socialMedia.map(
                ({
                  linkUrl,
                  mediaOutlet,
                  customIcon,
                  customOutletName,
                  id,
                }) => {
                  return (
                    <SocialIcons
                      customIcon={customIcon}
                      customOutletName={customOutletName}
                      href={linkUrl}
                      key={id}
                      socialMediaOutlet={mediaOutlet}
                      whereAmI="--footer"
                    />
                  )
                }
              )}
            </div>
          )}

          {formattedOffices.length > 0 && (
            <div
              className={`fr-footer__address-container${isMoreThanTwoModifier}`}
            >
              {formattedOffices.map(office => (
                <FooterLocation
                  addressModifier={isMoreThanTwoModifier}
                  key={office.id}
                  office={office}
                />
              ))}
            </div>
          )}
        </div>

        <div className="fr-footer__credits-container">
          <Credits creditsNavigation={creditsNavigation} />
        </div>
      </footer>
    </div>
  )
}
