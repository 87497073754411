import React, { useEffect, useState } from "react"
import { AnchorLink } from "components/anchor-link/AnchorLink"
import { useLayout } from "contexts/components/LayoutContext"
import { useSideNav } from "hooks/useSideNav"
import { Button } from "."
import { Variant } from "./buttons-types"

interface AnchorButtonParserProps {
  slug: string
  reference: string
  variant: Variant
  className: string
  extraClassNames: string
  children: React.ReactNode
}

export const AnchorButtonParser = ({
  slug,
  reference,
  variant,
  className,
  extraClassNames,
  children,
}: AnchorButtonParserProps) => {
  const { handleCloseNav } = useSideNav()
  const { slug: layoutSlug, linkedPagesUrlPathCodex } = useLayout()

  const pageSlug = linkedPagesUrlPathCodex[layoutSlug]

  const [refExists, setRefExists] = useState(null)
  useEffect(() => {
    const ref = document.getElementById(reference) !== null
    setRefExists(ref)
  }, [])

  if (
    reference !== undefined &&
    reference.includes("form") &&
    refExists === false
  ) {
    return (
      <AnchorLink
        to={`/contact-us#${reference}`}
        variant={variant}
        extraClassNames={extraClassNames}
        onAnchorLinkClick={handleCloseNav}
        stripHash={true}
        className={className}
      >
        {children}
      </AnchorLink>
    )
  }

  let anchorLinkSlug

  if (slug === "/404" || slug === layoutSlug) {
    anchorLinkSlug =
      reference && reference !== "form-banner" && reference !== "bpco-container"
        ? `/${pageSlug}#${reference}`
        : pageSlug?.startsWith("/")
        ? `${pageSlug}#${reference}`
        : `/${pageSlug}#${reference}`
    return (
      <AnchorLink
        to={anchorLinkSlug}
        className={className}
        onAnchorLinkClick={handleCloseNav}
      >
        {children}
      </AnchorLink>
    )
  }

  anchorLinkSlug = `${slug}#${reference}`

  // Layout has a useEffect to handle initial load of pages with a hash. E.g: /commercial-pest-control#bpco-container
  return (
    <AnchorLink
      to={anchorLinkSlug}
      variant={variant}
      className={className}
      extraClassNames={extraClassNames}
      onAnchorLinkClick={handleCloseNav}
    >
      {children}
    </AnchorLink>
  )
}
