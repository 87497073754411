import React, { createContext, useCallback, useState, ReactNode } from "react"

export type TrackingNumberModalContextData = {
  handleCloseModal: () => void
  handleOpenModal: () => void
  isModalOpen: boolean
}

interface TrackingNumberModalProviderProps {
  children: ReactNode
}

const TrackingNumberModalContext = createContext(
  {} as TrackingNumberModalContextData
)

const TrackingNumberModalProvider = ({
  children,
}: TrackingNumberModalProviderProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  return (
    <TrackingNumberModalContext.Provider
      value={{ isModalOpen, handleOpenModal, handleCloseModal }}
    >
      {children}
    </TrackingNumberModalContext.Provider>
  )
}

export { TrackingNumberModalProvider, TrackingNumberModalContext }
