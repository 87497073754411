import React from "react"

export const getPhoneIcon = (modifier = "", customClassName = "") => {
  return (
    <svg
      id="Group_302"
      data-name="Group 302"
      xmlns="http://www.w3.org/2000/svg"
      width="20.336"
      height="22"
      viewBox="0 0 35.91 35.909"
      className={`${
        customClassName ? customClassName : `phone-svg${modifier}`
      }`}
    >
      <path
        id="Path_13628"
        data-name="Path 13628"
        d="M5994.332,2289.512a1.124,1.124,0,0,1-1.275.34,23.692,23.692,0,0,1-13.268-13.269,1.124,1.124,0,0,1,.339-1.274l3.027-2.5a2.247,2.247,0,0,0,.763-2.222l-1.558-7.015a2.249,2.249,0,0,0-2.2-1.761h-5.986a2.26,2.26,0,0,0-2.253,2.4,35.964,35.964,0,0,0,33.5,33.5,2.259,2.259,0,0,0,2.4-2.253v-5.984a2.246,2.246,0,0,0-1.759-2.2l-7.016-1.56a2.246,2.246,0,0,0-2.221.763Z"
        transform="translate(-5971.922 -2261.808)"
        fill="#fff"
      />
      <g id="Group_301" data-name="Group 301" transform="translate(16.833 0)">
        <path
          id="Path_13629"
          data-name="Path 13629"
          d="M6316.361,2280.886a1.122,1.122,0,0,1-1.121-1.123,15.729,15.729,0,0,0-15.712-15.71,1.122,1.122,0,0,1,0-2.244,17.975,17.975,0,0,1,17.955,17.955A1.122,1.122,0,0,1,6316.361,2280.886Z"
          transform="translate(-6298.407 -2261.808)"
          fill="#fff"
        />
        <path
          id="Path_13630"
          data-name="Path 13630"
          d="M6311.873,2363.458a1.123,1.123,0,0,1-1.124-1.122,11.234,11.234,0,0,0-11.222-11.223,1.122,1.122,0,0,1,0-2.244,13.481,13.481,0,0,1,13.467,13.467A1.122,1.122,0,0,1,6311.873,2363.458Z"
          transform="translate(-6298.407 -2344.382)"
          fill="#fff"
        />
        <path
          id="Path_13631"
          data-name="Path 13631"
          d="M6307.384,2446.033a1.122,1.122,0,0,1-1.122-1.123,6.74,6.74,0,0,0-6.734-6.733,1.122,1.122,0,0,1,0-2.244,8.987,8.987,0,0,1,8.978,8.977A1.122,1.122,0,0,1,6307.384,2446.033Z"
          transform="translate(-6298.407 -2426.956)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const getLoginIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
    >
      <g id="icon" transform="translate(-2.627 -0.001)" opacity="1">
        <path
          id="Path_14710"
          data-name="Path 14710"
          d="M17.467,20H1.489a.891.891,0,0,1,0-1.766h15.2V1.766H1.489A.832.832,0,0,1,.717.885.835.835,0,0,1,1.489,0H17.467a.84.84,0,0,1,.775.885V19.118a.84.84,0,0,1-.775.885"
          transform="translate(4.384 0.001)"
          fill="#fff"
        />
        <path
          id="Path_14711"
          data-name="Path 14711"
          d="M13.872,4.384H.757A.829.829,0,0,1,0,3.5a.825.825,0,0,1,.757-.881H13.872a.827.827,0,0,1,.755.881.83.83,0,0,1-.755.885"
          transform="translate(2.627 6.361)"
          fill="#fff"
        />
        <path
          id="Path_14712"
          data-name="Path 14712"
          d="M4.408,11.025a.883.883,0,0,1-.624-1.509L7.059,6.241,3.808,2.994A.884.884,0,0,1,5.056,1.742L8.931,5.617a.884.884,0,0,1,0,1.252l-3.9,3.9a.88.88,0,0,1-.624.257"
          transform="translate(8.73 3.608)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const getBurgerIcon = () => {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="27"
      viewBox="0 0 30 27"
    >
      <rect
        id="Rectangle_812"
        data-name="Rectangle 812"
        width="30"
        height="4.995"
        transform="translate(0)"
        fill="#3089cb"
        className="fr-menu__rect"
      />
      <rect
        id="Rectangle_813"
        data-name="Rectangle 813"
        width="30"
        height="4.995"
        transform="translate(0 11.003)"
        fill="#3089cb"
        className="fr-menu__rect"
      />
      <rect
        id="Rectangle_814"
        data-name="Rectangle 814"
        width="30"
        height="4.995"
        transform="translate(0 22.005)"
        fill="#3089cb"
        className="fr-menu__rect"
      />
    </svg>
  )
}
export const getSideNavCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.933"
      height="24.93"
      viewBox="0 0 24.933 24.93"
    >
      <path
        id="close-icon"
        d="M288.582,309.783l8.9-8.9-8.906-8.9,3.56-3.56,8.906,8.9,8.906-8.906,3.561,3.561-8.907,8.906,8.9,8.9-3.56,3.559-8.9-8.9-8.9,8.9Z"
        transform="translate(-288.58 -288.413)"
        fill="#004880"
      />
    </svg>
  )
}
export const getGoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105.531"
      height="34.602"
      viewBox="0 0 105.531 34.602"
    >
      <g id="google-icon" data-name="Group 230" transform="translate(0 0)">
        <path
          id="path2998"
          d="M195.241,391.083H182.926v3.654h8.733c-.431,5.124-4.695,7.309-8.719,7.309a9.76,9.76,0,0,1,.012-19.519,9.477,9.477,0,0,1,6.584,2.641l2.559-2.649a13.008,13.008,0,0,0-9.273-3.656,13.425,13.425,0,1,0,.194,26.846c7.186,0,12.447-4.923,12.447-12.2a10.944,10.944,0,0,0-.223-2.424Z"
          transform="translate(-169.295 -378.863)"
          fill="#fff"
        />
        <path
          id="path3000"
          d="M244.515,402.149a8.628,8.628,0,1,0,8.658,8.658,8.526,8.526,0,0,0-8.658-8.658Zm.05,3.389a5.25,5.25,0,1,1-4.938,5.221,4.961,4.961,0,0,1,4.938-5.221Z"
          transform="translate(-208.482 -392.576)"
          fill="#fff"
        />
        <path
          id="path3005"
          d="M290.4,402.149a8.628,8.628,0,1,0,8.658,8.658,8.526,8.526,0,0,0-8.658-8.658Zm.051,3.389a5.25,5.25,0,1,1-4.938,5.221,4.961,4.961,0,0,1,4.938-5.221Z"
          transform="translate(-235.504 -392.576)"
          fill="#fff"
        />
        <path
          id="path3007"
          d="M335.967,402.171a8.639,8.639,0,0,0-.08,17.259,5.7,5.7,0,0,0,4.732-2.1v1.7c0,2.977-1.807,4.759-4.536,4.759a4.785,4.785,0,0,1-4.417-3.072l-3.316,1.386a8.3,8.3,0,0,0,7.76,5.081c4.612,0,8.126-2.9,8.126-9v-15.5H340.62v1.462a6.1,6.1,0,0,0-4.652-1.98Zm.336,3.382c2.274,0,4.609,1.942,4.609,5.257,0,3.37-2.33,5.228-4.659,5.228a4.9,4.9,0,0,1-4.773-5.2,4.973,4.973,0,0,1,4.824-5.29Z"
          transform="translate(-262.567 -392.589)"
          fill="#fff"
        />
        <path
          id="path3011"
          d="M395.543,402.121c-4.375,0-8.048,3.481-8.048,8.617a8.344,8.344,0,0,0,8.469,8.658,8.753,8.753,0,0,0,7.228-3.787l-2.983-1.984A4.828,4.828,0,0,1,395.982,416a4.439,4.439,0,0,1-4.232-2.615l11.569-4.8-.6-1.407a7.812,7.812,0,0,0-7.175-5.056Zm.151,3.319a3.439,3.439,0,0,1,3.193,1.843l-7.726,3.229a4.793,4.793,0,0,1,4.533-5.072Z"
          transform="translate(-297.788 -392.559)"
          fill="#fff"
        />
        <path
          id="path3015"
          d="M374.079,406.466h3.8V381.034h-3.8Z"
          transform="translate(-289.888 -380.141)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
export const OverlayCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.933"
    height="24.93"
    viewBox="0 0 24.933 24.93"
  >
    <path
      id="close-icon-2"
      data-name="close-icon"
      d="M288.582,309.783l8.9-8.9-8.906-8.9,3.56-3.56,8.906,8.9,8.906-8.906,3.561,3.561-8.907,8.906,8.9,8.9-3.56,3.559-8.9-8.9-8.9,8.9Z"
      transform="translate(-288.58 -288.413)"
    />
  </svg>
)
