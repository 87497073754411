import React, { ReactNode } from "react"
import { graphql, Link } from "gatsby"
import { IconType } from "react-icons/lib"
import { useSideNav } from "hooks/useSideNav"
import {
  ButtonWithoutClassName,
  LinkWithoutClassName,
  Variant,
} from "./buttons-types"

type ButtonComponentProps = {
  children: ReactNode
  variant: Variant
  extraClassNames?: string
  to?: string
  leftIcon?: IconType
  rightIcon?: IconType
  activeClassName?: string
}

export type ButtonProps = (LinkWithoutClassName | ButtonWithoutClassName) &
  ButtonComponentProps

export const Button = ({
  activeClassName,
  children,
  variant,
  to,
  extraClassNames = "",
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  ...rest
}: ButtonProps) => {
  const { isNavOpen, handleCloseNav } = useSideNav()
  const variantClassName = `button--${variant}`
  const classNames = extraClassNames
    ? `${variantClassName} ${extraClassNames}`
    : variantClassName

  if (to) {
    const onCloseNav = () => {
      if (isNavOpen) {
        handleCloseNav()
      }
    }

    return (
      <Link
        {...(rest as LinkWithoutClassName)}
        to={to}
        className={classNames}
        onClick={onCloseNav}
        activeClassName={activeClassName}
      >
        <>
          {LeftIcon && <LeftIcon />}
          {children}
          {RightIcon && <RightIcon />}
        </>
      </Link>
    )
  }

  return (
    <button
      role="button"
      {...(rest as ButtonWithoutClassName)}
      className={classNames}
    >
      {LeftIcon && <LeftIcon />}
      {children}
      {RightIcon && <RightIcon />}
    </button>
  )
}

export const query = graphql`
  fragment ButtonFragment on kontent_item_button {
    id
    system {
      type
    }
    elements {
      button_text {
        value
      }
      external_link {
        value
      }
      linked_page {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
      use_main_office_call_tracking_number {
        value {
          codename
        }
      }
      custom_call_tracking_number {
        value
      }
      cta_override {
        value {
          codename
        }
      }
    }
  }
`
