import React from "react"
import { SkeletonElement } from "../SkeletonElement"
import "./testimonial-skeleton.module.scss"

interface TestimonialSkeletonProps {
  hasAvatar?: boolean
  hasRating?: boolean
}

export const TestimonialSkeleton = ({
  hasAvatar,
  hasRating,
}: TestimonialSkeletonProps) => {
  return (
    <div className="fr-testimonial-skeleton__body">
      {hasAvatar && (
        <SkeletonElement
          type="avatar"
          additionalClassNames="fr-testimonial-skeleton__avatar"
        />
      )}

      <div className="fr-testimonial-skeleton__body--paragraph">
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" modifier="quarter" />
      </div>

      <div className="fr-testimonial-skeleton__reviewer">
        {hasRating && <SkeletonElement type="text" modifier="dime" />}
        <SkeletonElement type="text" modifier="quarter" />
      </div>
    </div>
  )
}
