import React, { createContext, useContext } from "react"

type LayoutContextData = {
  linkedPagesUrlPathCodex: Record<string, string>
  breadcrumbLocation: string
  slug: string
  parentSlug?: string
  isHomepage?: boolean
}

export const LayoutContext = createContext({} as LayoutContextData)

export function useLayout() {
  const context = useContext(LayoutContext)

  if (!context) {
    throw new Error("useLayout must be used within LayoutContext")
  }

  return context
}
