import React from "react"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import "./testimonial.module.scss"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"

interface TestimonialProps {
  review: string
  rating?: string
  reviewerName: string
  reviewerLocation: string
  hasFieldroutesReviews?: boolean
  avatar?: ImageBuilder
}

export const Testimonial = ({
  review,
  rating,
  reviewerName,
  reviewerLocation,
  hasFieldroutesReviews = false,
  avatar,
}: TestimonialProps) => {
  return (
    <div
      className={`fr-testimonial__body ${
        hasFieldroutesReviews
          ? "fr-testimonial__body--fadeIn"
          : "fr-testimonial__body"
      }`}
    >
      {avatar && (
        <Image
          image={avatar}
          alt={avatar.description}
          className="fr-testimonial__avatar fr-testimonial__avatar"
          width={avatar.width}
          height={avatar.height}
        />
      )}
      {hasFieldroutesReviews && <p>"{review}"</p>}
      {!hasFieldroutesReviews && (
        <div className="fr-testimonial__body--paragraph">
          <RichTextElement value={review} />
        </div>
      )}

      <div className="fr-testimonial__reviewer">
        <div className="fr-testimonial__reviewer--info">
          <strong>{reviewerName}</strong>
          {reviewerLocation && <span>{reviewerLocation}</span>}
        </div>

        {rating && (
          <span className="fr-testimonial__reviewer--rating-stars">
            {rating}
          </span>
        )}
      </div>
    </div>
  )
}
