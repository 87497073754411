import { useSideNav } from "hooks/useSideNav"
import React from "react"
import { ButtonElements } from "_types/Button"
import { Button } from "."
import { AnchorButtonParser } from "./AnchorButtonParser"
import { Variant } from "./buttons-types"
import { useGeneralSettings } from "hooks/useGeneralSettings"

interface CTAProps {
  componentType: "anchor_button" | "button"
  slug: string
  elements: ButtonElements
  extraClassNames: string
  label: string
  showPhoneIcon?: boolean
  onGetCTAVariant: (index: number) => Variant
  index: number
}

export const CTA = ({
  componentType,
  index,
  elements,
  slug,
  extraClassNames,
  label,
  onGetCTAVariant,
  showPhoneIcon = false,
}: CTAProps) => {
  const { handleCloseNav } = useSideNav()

  const formattedSlug = slug.startsWith("/") ? slug : `/${slug}`

  const getCTAClassName = (index: number) => {
    const ctaClassName = `button--${onGetCTAVariant(index)} ${extraClassNames}`
    return ctaClassName
  }

  if (componentType === "anchor_button") {
    const { custom_anchor_reference, anchor_reference } = elements

    const anchorReference =
      custom_anchor_reference?.value ||
      anchor_reference?.value[0]?.name ||
      "form-banner"

    return (
      <AnchorButtonParser
        slug={formattedSlug}
        reference={anchorReference}
        extraClassNames={extraClassNames}
        className={getCTAClassName(index)}
        variant={onGetCTAVariant(index)}
      >
        {label}
      </AnchorButtonParser>
    )
  }

  const { use_main_office_call_tracking_number, custom_call_tracking_number } =
    elements

  const useMainOfficeTrackingNumber =
    use_main_office_call_tracking_number?.value[0]?.codename === "yes"

  const customPhoneNumber = custom_call_tracking_number?.value ?? ""
  const hasPhoneNumber = !!customPhoneNumber

  if (useMainOfficeTrackingNumber || hasPhoneNumber) {
    const { trackingNumbers } = useGeneralSettings()

    const contactNumber = useMainOfficeTrackingNumber
      ? trackingNumbers[0].trackingNumber
      : customPhoneNumber

    return (
      <a
        href={`tel: ${contactNumber}`}
        className={`${getCTAClassName(index)} ${extraClassNames}`}
        onClick={handleCloseNav}
      >
        {label || contactNumber}
      </a>
    )
  }

  return (
    <Button
      to={formattedSlug}
      variant={onGetCTAVariant(index)}
      extraClassNames={extraClassNames}
      onClick={handleCloseNav}
    >
      {label}
    </Button>
  )
}
