import React, { useState, useEffect, useMemo } from "react"
import { useExternalScripts } from "hooks/useExternalScripts"
import { shuffle } from "assets/helper/shuffle"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { TestimonialBanner } from "components/testimonial/TestimonialBanner"

type FieldroutesReviews = {
  feedback: string
  reviewer: string
  city: string
  state: string
  profane: boolean
  rating: number
}

type PestroutesClientResponse = {
  reviews: FieldroutesReviews[]
}

export const SingleReview = () => {
  const [singleReview, setSingleReview] = useState<FieldroutesReviews>(
    {} as FieldroutesReviews
  )
  const [stars, setStars] = useState("★★★★★")
  const [showReview, setShowReview] = useState(false)

  const {
    fieldroutesReviewImages,
    googleReviewsUrl,
    fieldroutesReviewBackgroundImage,
  } = useIntegrationsSettings()

  const backgroundImageUrl = fieldroutesReviewBackgroundImage?.url ?? ""

  const {
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useExternalScripts()

  const randomReviewImage = useMemo(() => {
    const image = shuffle(fieldroutesReviewImages)?.single_image
    return image
  }, [fieldroutesReviewImages])

  /* eslint-disable */
  const getPestroutesClientReviews =
    async (): Promise<void | NodeJS.Timeout> => {
      // @ts-ignore: PestroutesClient was delayed imported
      if (typeof PestroutesClient !== "object") {
        return setTimeout(getPestroutesClientReviews, 100)
      }
      let result: PestroutesClientResponse

      try {
        // @ts-ignore: PestroutesClient was delayed imported
        result = await PestroutesClient?.getReviews({
          favorable: 1,
          minRating: 4,
          limit: 15,
          randomize: true,
        })
      } catch {
        return setTimeout(getPestroutesClientReviews, 100)
      }

      //remove reviews containing profanities
      const filteredReviews = result.reviews.filter(
        review => !review.profane && review.feedback.length > 15
      )

      //referenced randomized review to be used in the for loop
      const randomSingleReview = shuffle(filteredReviews)

      // This could be undefined
      if (!randomSingleReview) {
        console.error(
          "There are no reviews from FieldRoutes for this website, please disable the option in the settings"
        )
        return
      }

      let reviewStars = ""

      for (let i = 0; i < randomSingleReview.rating; i++) {
        reviewStars += "★"
      }

      setSingleReview(randomSingleReview)
      setStars(reviewStars)
    }

  useEffect(() => {
    if (Object.keys(singleReview).length > 0) {
      setShowReview(true)
    }
  }, [singleReview])

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    if (isPRClientAndJQueryScriptsLoaded) {
      getPestroutesClientReviews()
      return
    }

    // avoids loading jquery before the LCP is done
    // @ts-ignore: PestroutesClient was delayed imported
    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 4000)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [isPRClientAndJQueryScriptsLoaded])

  return (
    <TestimonialBanner
      hasFieldroutesReviews={true}
      hasReviewLoaded={showReview}
      googleReviewUrl={googleReviewsUrl}
      backgroundImageUrl={backgroundImageUrl}
      avatar={randomReviewImage}
      heading="Customer Reviews"
      reviewerName={singleReview?.reviewer}
      reviewerLocation={`${singleReview?.city}, ${singleReview?.state}`}
      review={singleReview?.feedback}
      rating={stars}
    />
  )
}
