import React from "react"
import { Link } from "gatsby"
import { ImageBuilder } from "_types/AssetsImage"
import { GoogleReview } from "components/google-reviews-banner/GoogleReview"
import { SkeletonElement } from "components/skeleton/SkeletonElement"
import { TestimonialSkeleton } from "components/skeleton/Testimonial/TestimonialSkeleton"
import { Testimonial } from "./Testimonial"
import "./testimonial.module.scss"

interface TestimonialBannerProps {
  review: string
  rating?: string
  reviewerName: string
  reviewerLocation: string
  hasFieldroutesReviews?: boolean
  hasReviewLoaded?: boolean
  backgroundImageUrl?: string
  avatar?: ImageBuilder
  googleReviewUrl?: string
  heading?: string
}

export const TestimonialBanner = ({
  review,
  rating,
  reviewerName,
  reviewerLocation,
  hasFieldroutesReviews = false,
  avatar,
  googleReviewUrl,
  backgroundImageUrl,
  heading,
  hasReviewLoaded = true,
}: TestimonialBannerProps) => {
  const googleReviewModifier = googleReviewUrl
    ? "fr-testimonial--has-google-review"
    : ""
  return (
    <section
      className={`fr-testimonial u-content-block-padding u-content-block-box ${googleReviewModifier} ${
        hasFieldroutesReviews ? "fr-testimonial--fadeIn" : ""
      }`}
    >
      <div className="fr-container fr-testimonial__wrapper">
        {heading && (
          <h2 className="fr-testimonial__heading u-container-header">
            {heading}
          </h2>
        )}
        {hasReviewLoaded ? (
          <Testimonial
            hasFieldroutesReviews={hasFieldroutesReviews}
            reviewerName={reviewerName}
            review={review}
            avatar={avatar}
            reviewerLocation={reviewerLocation}
          />
        ) : (
          <TestimonialSkeleton hasAvatar={!!avatar} hasRating={false} />
        )}

        {hasFieldroutesReviews && !hasReviewLoaded && (
          <SkeletonElement
            type="text"
            additionalClassNames={"fr-testimonial-skeleton__button"}
          />
        )}

        {hasFieldroutesReviews && hasReviewLoaded && (
          <Link
            to="/reviews"
            className="button--outline-ghost fr-testimonial__button"
          >
            Read All Reviews
          </Link>
        )}
        {googleReviewUrl && (
          <div className="fr-testimonial__footer">
            <GoogleReview url={googleReviewUrl} />
          </div>
        )}
      </div>
    </section>
  )
}
