import React from "react"
import { graphql } from "gatsby"
import { ImageElement } from "@kentico/gatsby-kontent-components"
import {
  ImageItem,
  ImageElementProps,
} from "@kentico/gatsby-kontent-components/dist/image-element"

interface ImageProps extends ImageElementProps {
  image: ImageItem
}

export const Image = ({ image, ...rest }: ImageProps) => {
  return <ImageElement image={image} {...rest} />
}

export const singleImageFragment = graphql`
  fragment SingleImageFragment on kontent_item_image {
    system {
      type
    }
    id
    elements {
      caption {
        value
      }
      single_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`

export const assetElement = graphql`
  fragment KenticoAssetElementFragment on kontent_item_asset_element {
    description
    height
    url
    width
  }
`
