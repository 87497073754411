import { ExternalLink } from "components/external-link/ExternalLink"
import { useLayout } from "contexts/components/LayoutContext"
import { useSideNav } from "hooks/useSideNav"
import React from "react"
import { Button } from "."
import { Variant } from "./buttons-types"

interface NavigationProps {
  externalLink?: string
  onGetNavigationVariant: (index: number) => Variant
  extraClassNames: string
  label: string
  slug: string
  index: number
}

export const Navigation = ({
  externalLink,
  onGetNavigationVariant,
  extraClassNames,
  label,
  slug,
  index,
}: NavigationProps) => {
  const { handleCloseNav } = useSideNav()
  const { linkedPagesUrlPathCodex } = useLayout()

  const slugFromCodex = linkedPagesUrlPathCodex[slug]
  const formattedSlug = slugFromCodex.startsWith("/")
    ? slugFromCodex
    : `/${slugFromCodex}`

  const getNavigationClassName = (index: number) => {
    const ctaClassName = `button--${onGetNavigationVariant(
      index
    )} ${extraClassNames}`
    return ctaClassName
  }

  if (externalLink) {
    return (
      <ExternalLink
        href={externalLink}
        className={getNavigationClassName(index)}
        onClick={handleCloseNav}
      >
        {label}
      </ExternalLink>
    )
  }
  return (
    <Button
      to={formattedSlug}
      variant={onGetNavigationVariant(index)}
      extraClassNames={extraClassNames}
      onClick={handleCloseNav}
    >
      {label}
    </Button>
  )
}
